import request from "@/utils/request";
const controller = "/News";

export function getNewsTypeOptions() {
  return request({
    url: `${controller}/GetNewsTypeOptions`,
    method: "get"
  });
}

export function getPageList(query) {
  return request({
    url: `${controller}/GetPageList`,
    method: "get",
    params: query
  });
}

export function getAllList(query) {
  return request({
    url: `${controller}/GetAllList`,
    method: "get",
    params: query
  });
}

export function getInfo(id) {
  return request({
    url: `${controller}/GetInfo`,
    method: "get",
    params: {
      id
    }
  });
}

export function getNewsList(id) {
  return request({
    url: `${controller}/GetNewsList`,
    method: "get",
    params: {
      id
    }
  });
}

export function getCurrentAndAdjacent(id) {
  return request({
    url: `${controller}/GetCurrentAndAdjacent`,
    method: "get",
    params: {
      id
    }
  });
}

export function createEntity(data) {
  return request({
    url: `${controller}/Insert`,
    method: "post",
    data
  });
}

export function updateEntity(data) {
  return request({
    url: `${controller}/Update`,
    method: "post",
    data
  });
}

export function deleteEntity(data) {
  return request({
    url: `${controller}/Delete`,
    method: "post",
    params: data
  });
}
